//APERIDES GARAGE DAPP
//AUTHOR: twitter.com/Crypto_n_that

//IMPORTS
import React, { useEffect, useState } from "react"
import Web3 from "web3"
import NFTCard from './NFTCard'
import WalletConnectProvider from "@walletconnect/web3-provider"
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import { createAlchemyWeb3 } from '@alch/alchemy-web3';
import ApeContainer from './ApeContainer'
import fetch from 'node-fetch'
import letsGenerate from './newGenerator.js';

const alchemyWeb3 = createAlchemyWeb3('https://eth-mainnet.g.alchemy.com/v2/FeBF4O8PdhwNkQFNYAnjpWkjBuq74TMQ');

const GarageApp = ({ backButton }) => {

//STATES, VARIABLES AND CONSTANTS

    

  //GLOBAL STATE HOOKS
  const [walletA, setWalletA] = useState(null) //42 CHAR WALLET ADDRESS
  const [nfts,setNfts] = useState([]) //Array of holders ape nfts that are CURRENTLY IN VIEW
  const [allNfts, setAllNfts]= useState([[1],[2],[3],[4],[5],[6],[7],[8],[9],[10],[11],[12],[13],[14],[15],[16],[17]]) //Array of all a users Apes
  const [nftR, setNftsR] = useState([]) //Array of holders rides 

  const [expanded, setExpanded] = useState(false) //Boolean to track if ape nft view is expanded

  const [collection, chooseCollection] = useState(null) //Chosen collection DATA
  const [apeData, setApeData] = useState([]) //Chosen Ape DATA
  const [rideData, setRideData] = useState([]) //Chosen Ride DATA

  const [generated, setGenerated] = useState(false)
  const [popUp, setPopUp] = useState(false)

  const [state, setState] = useState('collection')
  const [chainError,setChainError] = useState(false)
  const [applicationError, setApplicationError] = useState("")
  const [popUpData, setPopUpData] = useState({display: false, title: "", message: "", type: "c-loader"})
  const [collectionBorders, setCollectionBordersState] = useState(["","","","","","","","","","","","","","","","",""])

  const [safeMint,setSafeMint] = useState(false);
  const [receivingAddress, setReceivingAddress] = useState("")
  const [isApproved, setIsApproved] = useState(false)
  const [isSafeMintInProgress, setISMIP] = useState(false)
    
  const [mintInProgress, setMintInProgress] = useState(false)


  const [userWeb3,setUserWeb3] = useState()


  const [fetched, setFetched] = useState(true) //Boolean to track fetch progress
  const [osLink, setOsLink] = useState(["none"]) //2 Length Array with Marketplace Links of chosen collections (OPENSEA, LOOKSRARE)

  const contractAddresses = [
      '0xa08b319f0f09ae8261db2034d43bf40c673f0ad0',
      '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d',
      '0x60e4d786628fea6478f785a6d7e704777c86a7c6',
      '0x22c08c358f62f35b742d023bf2faf67e30e5376e',
      '0xae63b956f7c77fba04e2eea59f7b8b2280f55431',
      '0x4b103d07c18798365946e76845edc6b565779402',
      '0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e',
      '0x984f7b398d577c0adde08293a53ae9d3b6b7a5c5',
      '0x1b1bff222999bcd6fd07b64d7880e6a95d54acaa',
      '0x2d0d57d004f82e9f4471caa8b9f8b1965a814154',
      '0x2120d19431e0dd49411e5412629f8e41a72cfabd',
      '0xe9626ef8dc3a8bb6bf9e18b44d35266a7ba3d11d',
      '0xe83dd605b70b47c8af86580bdd4fcb987ff36e60',
      '0x87212aa99f65611f6d67e0fbad76d06478753704',
      '0xbc4e8115e17d96cfd8e3ecef2b0d6e19d00f70b2',
      '0x8943c7bac1914c9a7aba750bf2b6b09fd21037e0',
      '0xfa969c60a78195c631787d4585ba15a07578c979']
                              //Array of addresses of supported ape collections

  const contractNames = ['TRAC','BAYC','MAYC','0xAPE','0xMAYC','GACC','DAW','SAC','LAYC','PAYC','JBAS','ARC','BTFD','BADDIES', 'BTYC', 'LION','AAS']

  const openseaLinks = ["teenrebelapeclub","boredapeyachtclub",
                        "mutant-ape-yacht-club","0xapes-trilogy",
                        "0xmayc-official", "grandpaapecountryclub",
                        "desperate-ape-wives","sac",
                        "official-layc","paycgenesis",
                        "japanesebornapesociety", "aperidersclub",
                        "btfdrabbits","baddiesofficial",
                        "bored-ted-yacht-club","lazy-lions",
                        "angryapes-society-nft"]
                        //Array of opensea links for supported collections


const getRideMeta = async (tokenID) => {

    let data = await fetch('https://app.aperides.io/api/getRideMetadata/'+tokenID).then((response) => response.json())
    return data

}

const getARCMeta = async (tokenID) => {
    let data = await fetch('https://app.aperides.io/api/getARCMetadata/'+tokenID).then((response) => response.json())
    return data

}

const getNftsFromAlchemy = async (_owner, _pageKey=null, _array=[]) => {

    let array = _array
    let response = {}

    if (_pageKey===null) {

    response = await alchemyWeb3.alchemy.getNfts({owner: _owner, contractAddresses: ['0xa08b319f0f09ae8261db2034d43bf40c673f0ad0',
                                                                                     '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d',
                                                                                     '0x60e4d786628fea6478f785a6d7e704777c86a7c6',
                                                                                     '0x22c08c358f62f35b742d023bf2faf67e30e5376e',
                                                                                     '0xae63b956f7c77fba04e2eea59f7b8b2280f55431',
                                                                                     '0x4b103d07c18798365946e76845edc6b565779402',
                                                                                     '0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e',
                                                                                     '0x984f7b398d577c0adde08293a53ae9d3b6b7a5c5',
                                                                                     '0x1b1bff222999bcd6fd07b64d7880e6a95d54acaa',
                                                                                     '0x2d0d57d004f82e9f4471caa8b9f8b1965a814154',
                                                                                     '0x2120d19431e0dd49411e5412629f8e41a72cfabd',
                                                                                     '0xe9626ef8dc3a8bb6bf9e18b44d35266a7ba3d11d',
                                                                                     '0xe83dd605b70b47c8af86580bdd4fcb987ff36e60',
                                                                                     '0x87212aa99f65611f6d67e0fbad76d06478753704',
                                                                                     '0xbc4e8115e17d96cfd8e3ecef2b0d6e19d00f70b2',
                                                                                     '0x8943c7bac1914c9a7aba750bf2b6b09fd21037e0',
                                                                                     '0xfa969c60a78195c631787d4585ba15a07578c979',
                                                                                     '0x1748b24faf9780b74e5a9f3feb41553e712e94aa'], withMetadata: true})
    } else {
        response = await alchemyWeb3.alchemy.getNfts({owner: _owner, pageKey: _pageKey, withMetadata: true})
    }


    response.ownedNfts.forEach((item)=>{
        array.push(item);
    })


    if (response.pageKey) {
        await getNftsFromAlchemy(_owner, response.pageKey, array);
    } else {
        console.log("end")
        return array
    }

    return array


}






//WALLETS

  //Connect To a Web3 Wallet
  //Sets walletA & shortWallet on wallet connection success
  const connectMM = async () => {

    setGenerated(false)
    setChainError(false)

    if(typeof window.ethereum !== 'undefined') {
      const chainID= await window.ethereum.request({ method: 'eth_chainId' });
      console.log("CHAIN ID: "+chainID.slice(2))
      if ((chainID.slice(2)) !== "1"){
        setChainError(true)
        return
      }

      const accounts = await window.ethereum.request({method: 'eth_requestAccounts'})

      setFetched(false)

      await getUsersRides(accounts[0])
        
      const web3 = new Web3(window.ethereum)

      window.ethereum.on('chainChanged', chainId => {
        console.log(chainId)
        if (chainId !== "1"){
          setApplicationError("Application Error: Chain changed, please change back to Ethereum to continue")
        } else {
          setApplicationError("")
        }
      });

      window.ethereum.on('accountsChanged', (accounts) => {
            setState("collection")
            setWalletA(accounts[0])
            fetchAllApes2(accounts[0]).then((x)=>{
                setAllNfts(x)
                setCollectionBorders(x)
                setFetched(true)
                setWalletA(accounts[0])
                setSafeMint(false)
                setReceivingAddress("")
                setIsApproved(false)
                setISMIP(false)
          })
      });
      
      setUserWeb3(web3)
        await fetchAllApes2(accounts[0]).then((x)=>{
            setAllNfts(x)
            setCollectionBorders(x)
            setFetched(true)
            setWalletA(accounts[0])
            setSafeMint(false)
            setReceivingAddress("")
            setIsApproved(false)
            setISMIP(false)
            document.getElementById("walletPopUp").style.transform = "scale(0)"
        })
            
    } else {
      return
    }
  }

  const  connectWC = async () => {
    setGenerated(false)
    setChainError(false)
      
    const provider = new WalletConnectProvider({infuraId: "99850c159db84348b7bdc4bf33a1e267"});
    
    provider.walletConnectProvider = undefined;
    await provider.enable().catch((error) => {
        if (error) {
          console.log("Modal Closed")
          return
        }
        document.getElementById("walletPopUp").style.transform = "scale(0)"
    })

    const web3 = new Web3(provider)

      let chainID = await web3.eth.getChainId()
      console.log(chainID)

      if (chainID !== 1){
        setChainError(true)
        return
      }

      provider.on("chainChanged", chainId => {
        if (chainId !== 1){
          setApplicationError("Application Error: Chain changed, please change back to Ethereum to continue")
        } else {
          setApplicationError("")
        }
      });

      provider.on("accountsChanged", accounts => {
        setState("collection")
          setWalletA(accounts[0])
          fetchAllApes2(accounts[0]).then((x)=>{
              setAllNfts(x)
              setCollectionBorders(x)
              setFetched(true)
              setWalletA(accounts[0])
              setSafeMint(false)
              setReceivingAddress("")
              setIsApproved(false)
              setISMIP(false)
          
      });
      })

      const accounts = await web3.eth.getAccounts();
      setFetched(false)

      await getUsersRides(accounts[0])
      
      setUserWeb3(web3)
      setWalletA(accounts[0])
      await fetchAllApes2(accounts[0]).then((x)=>{
          setAllNfts(x)
          setCollectionBorders(x)
          setSafeMint(false)
          setReceivingAddress("")
          setIsApproved(false)
          setISMIP(false)
          document.getElementById("walletPopUp").style.transform = "scale(0)"
          setFetched(true)
      })

  }
    
const connectCBW = async () => {

    const coinbaseWallet = new CoinbaseWalletSDK({
    appName: `Ape Rides`,
    appLogoUrl: `https://garage.aperides.io/logo192.png`,
    darkMode: true
    })

    const ethereum = coinbaseWallet.makeWeb3Provider('https://eth-mainnet.g.alchemy.com/v2/FeBF4O8PdhwNkQFNYAnjpWkjBuq74TMQ', 1)

    const web3 = new Web3(ethereum)

    await ethereum.enable().then((accounts) => {
      console.log(`User's address is ${accounts[0]}`)
      web3.eth.defaultAccount = accounts[0]
    })

    const accounts = await web3.eth.getAccounts();
    
    ethereum.on("chainChanged", chainId => {
      if (chainId !== 1){
        setApplicationError("Application Error: Chain changed, please change back to Ethereum to continue")
      } else {
        setApplicationError("")
      }
    });

    ethereum.on('accountsChanged', async (accounts) => {
        setState("collection")
          setWalletA(accounts[0])
          fetchAllApes2(accounts[0]).then((x)=>{
              setAllNfts(x)
              setCollectionBorders(x)
              setFetched(true)
              setWalletA(accounts[0])
              setSafeMint(false)
              setReceivingAddress("")
              setIsApproved(false)
              setISMIP(false)
          
      })
    });
    
    await getUsersRides(accounts[0])
    
    setUserWeb3(web3)
    setWalletA(accounts[0])
    await fetchAllApes2(accounts[0]).then((x)=>{
        setAllNfts(x)
        setCollectionBorders(x)
        setSafeMint(false)
        setReceivingAddress("")
        setIsApproved(false)
        setISMIP(false)
        document.getElementById("walletPopUp").style.transform = "scale(0)"
        setFetched(true)
    })

}

  /*
  const checkForENS = (address,web3) => {
    const ensABI = require("./abis/ensABI.json")
    const ensContract = new web3.eth.Contract(ensABI, "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85");

  }*/

  const popUpWallet = () => {
    if(typeof window.ethereum == 'undefined') {
      document.getElementById("metamask").className="walletError flexc"
    }
    document.getElementById("walletPopUp").style.display = "flex"
    document.getElementById("walletPopUp").style.transform = "scale(1)"
  }




//GENERATE
  //ApeContainer Callback Function
  //Runs once user clicks generate button
  const dataCallback = async () => {
    if (apeData.contract === "0x60e4d786628fea6478f785a6d7e704777c86a7c6") {
        if ([1796, 4318, 4849, 6957, 9209, 30000, 30001, 30002, 30003, 30004, 30005, 30006].includes(apeData.tokenId)) {
            setPopUp(false)
            let data = popUpData
            data.title = "Congrats on your 1 of 1 Ape!"
            data.message = "We want to make it right for you. Pleases create a ticket in our Discord or dm @aperidesNFT on Twitter"
            data.type = "c-error"
            setPopUpData(data)
            setPopUp(true)
            return
        }
    } else if (apeData.contract === "0x4b103d07c18798365946e76845edc6b565779402") {
        if ([0, 1, 2, 3, 5, 6, 7, 8, 9, 156, 576, 1713, 2976, 3023, 3622, 3767, 3867].includes(apeData.tokenId)) {
            setPopUp(false)
            let data = popUpData
            data.title = "Congrats on your 1 of 1 Ape!"
            data.message = "We want to make it right for you. Pleases create a ticket in our Discord or dm @aperidesNFT on Twitter"
            data.type = "c-error"
            setPopUpData(data)
            setPopUp(true)
            return
        }
    } else if (apeData.contract === "0x1b1bff222999bcd6fd07b64d7880e6a95d54acaa") {
        if ([742, 3727, 5856, 6881, 9747].includes(apeData.tokenId)) {
            setPopUp(false)
            let data = popUpData
            data.title = "Congrats on your 1 of 1 Ape!"
            data.message = "We want to make it right for you. Pleases create a ticket in our Discord or dm @aperidesNFT on Twitter"
            data.type = "c-error"
            setPopUpData(data)
            setPopUp(true)
            return
        }
    } else if (apeData.contract === "0xa08b319f0f09ae8261db2034d43bf40c673f0ad0") {
        if (apeData.tokenId===7709) {
            setPopUp(false)
            let data = popUpData
            data.title = "Congrats on your 1 of 1 Ape!"
            data.message = "We want to make it right for you. Pleases create a ticket in our Discord or dm @aperidesNFT on Twitter"
            data.type = "c-error"
            setPopUpData(data)
            setPopUp(true)
            return
        }
    } else if (apeData.contract === "0x8943c7bac1914c9a7aba750bf2b6b09fd21037e0") {
      if ([16, 2349, 7384, 678, 9431, 6483].includes(apeData.tokenId)) {
          setPopUp(false)
          let data = popUpData
          data.title = "Congrats on your 1 of 1 Ape!"
          data.message = "We want to make it right for you. Pleases create a ticket in our Discord or dm @aperidesNFT on Twitter"
          data.type = "c-error"
          setPopUpData(data)
          setPopUp(true)
          return
      }
    } else if (apeData.contract === "0xfa969c60a78195c631787d4585ba15a07578c979") {
      if ([7245, 1757, 1968, 245, 3557, 3691, 498, 582, 7806, 9213].includes(apeData.tokenId)) {
          setPopUp(false)
          let data = popUpData
          data.title = "Congrats on your 1 of 1 Ape!"
          data.message = "We want to make it right for you. Pleases create a ticket in our Discord or dm @aperidesNFT on Twitter"
          data.type = "c-error"
          setPopUpData(data)
          setPopUp(true)
          return
      }
    }
    document.getElementById("canvasMain").style.display = "flex";
    document.getElementById("canvasHider").style.display = "flex";
    document.getElementById('canvasMain').scrollIntoView({behavior: 'smooth'});
    setGenerated(true)
      letsGenerate(rideData.ride,apeData,getCanvas(),rideData.tokenId).then((gStatus) =>{
          console.log(gStatus)
      })

  }
 

//Get background function
//INPUT: data (ARRAY of NFT data)
//OUTPUT: STRING filepath of background related to ape
const getBackground = (data,contract) => {
  let bg = '';
  let eyes = '';
  let headwear = ''
  let cName = contractNames[contractAddresses.indexOf(contract.toLowerCase())].toLowerCase();
      
    data.attributes.forEach((atr) => {

      try {
        if (atr.key.toLowerCase() === 'background') {
          bg = atr.value.toLowerCase().replace(/\s/g, '')
        } else if (atr.key.toLowerCase() === 'eyes') {
          eyes = atr.value.toLowerCase().replace(/\s/g, '')
        } else if (atr.key.toLowerCase() === 'headwear') {
          headwear = atr.value.toLowerCase().replace(/\s/g, '')
        }
      } catch {
        if (atr.trait_type.toLowerCase() === 'background') {
          bg = atr.value.toLowerCase().replace(/\s/g, '')
        } else if (atr.trait_type.toLowerCase() === 'eyes') {
          eyes = atr.value.toLowerCase().replace(/\s/g, '')
        } else if (atr.trait_type.toLowerCase() === 'headwear') {
          headwear = atr.value.toLowerCase().replace(/\s/g, '')
        }
      } 

      })

      switch (contract) {
        case '0xa08b319f0f09ae8261db2034d43bf40c673f0ad0':
          if (eyes === 'lasers') {
            return cName+'/bglasers/'+bg+'.png'
          } else if (eyes === 'bluebeams') {
            return cName+'/bgbluebeams/'+bg+'.png'
          } else {
            return cName+'/bg/'+bg+'.png'
          }
        case '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d':
          if (eyes === 'lasereyes') {
            return cName+'/bglasereyes/'+bg+'.png'
          } else if (eyes === 'bluebeams') {
            return cName+'/bgbluebeams/'+bg+'.png'
          } else {
            return cName+'/bg/'+bg+'.png'
          }
        case '0x60e4d786628fea6478f785a6d7e704777c86a7c6':
          if (eyes === 'm1bluebeams') {
            return cName+'/bgm1bluebeams/'+bg+'.png'
          } else if (eyes === 'm2lasereyes') {
            return cName+'/bgm2lasereyes/'+bg+'.png'
          } else if (eyes === 'm2bluebeams') {
            return cName+'/bgm2bluebeams/'+bg+'.png'
          } else {
            return cName+'/bg/'+bg+'.png'
          }
        case '0x22c08c358f62f35b742d023bf2faf67e30e5376e':
              cName = '0xapes'
          if (eyes === 'lasereyes') {
            return cName+'/bglasereyes.png'
          } else if (eyes === 'bluebeams') {
            return cName+'/bgbluebeams.png'
          } else {
            return cName+'/bg.png'
          }
        case '0xae63b956f7c77fba04e2eea59f7b8b2280f55431':
          if (eyes === 'm1lasereyes') {
            return cName+'/bg.png'
          } else if (eyes === 'm1bluebeams') {
            return cName+'/bgm1bluebeams.png'
          } else {
            return cName+'/bg.png'
          }
        case '0x4b103d07c18798365946e76845edc6b565779402':
          if (eyes === 'laser') {
            return cName+'/bglaser/'+bg+'.png'
          } else if (eyes === 'bluebeams') {
            return cName+'/bgbluebeams/'+bg+'.png'
          } else {
            return cName+'/bg/'+bg+'.png'
          }
        case '0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e':
          if (eyes === 'lasereyes') {
            return cName+'/bglasereyes/'+bg+'.png'
          } else if (eyes === 'pinkbeam') {
            return cName+'/bgpinkbeam/'+bg+'.png'
          } else {
            return cName+'/bg/'+bg+'.png'
          }
        case '0x984f7b398d577c0adde08293a53ae9d3b6b7a5c5':
              cName = 'SAC'
          if (eyes === 'redlaser') {
            return cName+'/bgredlaser/'+bg+'.png'
          } else if (eyes === 'bluelaser') {
            return cName+'/bgbluelaser/'+bg+'.png'
          } else if (eyes === 'greenlaser') {
            return cName+'/bggreenlaser/'+bg+'.png'
          } else {
            return cName+'/bg/'+bg+'.png'
          };
          case '0x1b1bff222999bcd6fd07b64d7880e6a95d54acaa':
              return cName+'/bg/'+bg+'.png'
          case '0x2d0d57d004f82e9f4471caa8b9f8b1965a814154':
              if (eyes === 'bluebeams') {
                return cName+'/bgbluebeams/'+bg+'.png'
              } else if (eyes === 'lasereyes') {
                return cName+'/bglasereyes/'+bg+'.png'
              } else {
                return cName+'/bg/'+bg+'.png'
              }
          case '0x2120d19431e0dd49411e5412629f8e41a72cfabd':
            return cName+'/bg/'+bg+'.png'
          case '0xe9626ef8dc3a8bb6bf9e18b44d35266a7ba3d11d':
            return cName+'/bg/'+bg+'.png'
          case '0xe83dd605b70b47c8af86580bdd4fcb987ff36e60':
              if (eyes === 'greenbeams') {
                return cName+'/bggreenbeams/'+bg+'.png'
              } else if (eyes === 'lasereyes') {
                return cName+'/bglasereyes/'+bg+'.png'
              } else {
                return cName+'/bg/'+bg+'.png'
              }
          case '0x87212aa99f65611f6d67e0fbad76d06478753704':
            if (eyes === 'inferno') {
                return cName+'/bginferno/'+bg+'.png'
              } else if (eyes === 'laserglasses') {
                return cName+'/bglaserglasses/'+bg+'.png'
              } else {
                return cName+'/bg/'+bg+'.png'
              }
          case '0xbc4e8115e17d96cfd8e3ecef2b0d6e19d00f70b2':
            if (eyes === 'bluebeams') {
              return cName+'/bluebeams/'+bg+'.png'
            } else if (eyes === 'lasereyes') {
              return cName+'/lasereyes/'+bg+'.png'
            } else {
              return cName+'/bg/'+bg+'.png'
            }
          case '0x8943c7bac1914c9a7aba750bf2b6b09fd21037e0':
            return cName+'/bg/'+bg+'.png'
          case '0xfa969c60a78195c631787d4585ba15a07578c979':
            if (headwear === 'plasma') {
              return cName+'/bgplasma/'+bg+'.png'
            } else {
              return cName+'/bg/'+bg+'.png'
            }
        default:
          return cName+'/bg/'+bg+'.png'
      }
  }

  //Get fur function
  //INPUT: data (ARRAY of NFT data)
  //OUTPUT: STRING folder filepath of fur related to ape
  const getFur = (data, contract) => {
    let fur = ''
    data.attributes.forEach((atr) => {
        if (contract==="0x1b1bff222999bcd6fd07b64d7880e6a95d54acaa" || contract==="0x8943c7bac1914c9a7aba750bf2b6b09fd21037e0"){
                      try {
              if (atr.key.toLowerCase() === 'body') {
                fur = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
                  return fur
              }
            } catch {
              if (atr.trait_type.toLowerCase() === 'body') {
                fur = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
                  return fur
              }
            }
      
        } else if (contract==="0x87212aa99f65611f6d67e0fbad76d06478753704") {
                    try {
              if (atr.key.toLowerCase() === 'skins') {
                fur = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
                  return fur
              }
            } catch {
              if (atr.trait_type.toLowerCase() === 'skins') {
                fur = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
                  return fur
              }
            }
          } else if (contract==="0xfa969c60a78195c631787d4585ba15a07578c979") {
            try {
              if (atr.trait_type.toLowerCase() === 'base fur') {
                fur = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
                  return fur
              }
    } catch {
      if (atr.key.toLowerCase() === 'base fur') {
        fur = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
          return fur
      }
    }
} else {
          try {
        if (atr.key.toLowerCase() === 'fur') {
          fur = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
            return fur
        }
      } catch {
        if (atr.trait_type.toLowerCase() === 'fur') {
          fur = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
            return fur
        }
      }

        }
        })
            
      
    return fur
  }

  //Get clothing function
  //INPUT: data (ARRAY of NFT data)
  //OUTPUT: STRING folder filepath of clothing related to ape
  const getClothing = (data, contract) => {
    let clothing = ''
    data.attributes.forEach((atr) => {
      try {
      if (atr.key.toLowerCase() === 'clothing' || atr.key.toLowerCase() ==='clothes' || atr.key.toLowerCase() ==='bodygear') {
        clothing = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
      }
      } catch {
        if (atr.trait_type.toLowerCase() === 'clothing' || atr.trait_type.toLowerCase() ==='clothes' || atr.trait_type.toLowerCase() ==='bodygear') {
        clothing = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
      }
      }
    })
      
    return clothing
  }

  //Get mouth function (TRAC PRIMARY BEER TRAIT)
//INPUT: data METADATA object
//OUTPUT: STRING mouth type
const getMouth = (data) => {
  let mouth = ''
  data.attributes.forEach((atr) => {
    try {
    if (atr.key.toLowerCase() === 'mouth') {
      mouth = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    } catch {
      if (atr.trait_type.toLowerCase() === 'mouth') {
      mouth = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    }
  })
    
  return mouth
}

//Get mouth function (TRAC PRIMARY BEER TRAIT)
//INPUT: data METADATA object
//OUTPUT: STRING mouth type
const getBack = (data) => {
  let back = ''
  data.attributes.forEach((atr) => {
    try {
    if (atr.key.toLowerCase() === 'back') {
      back = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    } catch {
      if (atr.trait_type.toLowerCase() === 'back') {
      back = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    }
  })
    
  return back
}

//Get mouth function (TRAC PRIMARY BEER TRAIT)
//INPUT: data METADATA object
//OUTPUT: STRING mouth type
const getNeck = (data) => {
  let neck = ''
  data.attributes.forEach((atr) => {
    try {
    if (atr.key.toLowerCase() === 'neck') {
      neck = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    } catch {
      if (atr.trait_type.toLowerCase() === 'neck') {
      neck = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    }
  })
    
  return neck
}

//Get mouth function (TRAC PRIMARY BEER TRAIT)
//INPUT: data METADATA object
//OUTPUT: STRING mouth type
const getHandbag = (data) => {
  let handbag = ''
  data.attributes.forEach((atr) => {
    try {
    if (atr.key.toLowerCase() === 'handbags') {
      handbag = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    } catch {
      if (atr.trait_type.toLowerCase() === 'handbags') {
      handbag = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    }
  })
    
  return handbag
}

//Get mouth function (TRAC PRIMARY BEER TRAIT)
//INPUT: data METADATA object
//OUTPUT: STRING mouth type
const getNecklace = (data) => {
  let necklace = ''
  data.attributes.forEach((atr) => {
    try {
    if (atr.key.toLowerCase() === 'necklace') {
      necklace = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    } catch {
      if (atr.trait_type.toLowerCase() === 'necklace') {
      necklace = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    }
  })
    
  return necklace
}

//Get mouth function (TRAC PRIMARY BEER TRAIT)
//INPUT: data METADATA object
//OUTPUT: STRING mouth type
const getHair = (data) => {
  let hair = ''
  data.attributes.forEach((atr) => {
    try {
    if (atr.key.toLowerCase() === 'hair') {
      hair = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    } catch {
      if (atr.trait_type.toLowerCase() === 'hair') {
      hair = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    }
  })
    
  return hair
}

const getMetadataTrait = (data,_trait,lowercase=true) => {
  let _value = ''
  data.attributes.forEach((atr) => {
    try {
    if (atr.key.toLowerCase() === _trait) {
        if (lowercase) {
      _value = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
        } else {
            _value = atr.value
        }
    }
    } catch {
      if (atr.trait_type.toLowerCase() === _trait) {
          if (lowercase) {
      _value = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
      } else {
          _value = atr.value
      }
    }
    }
  })
    
  return _value
}

  //return canvas context
  const getCanvas = () => {
    return document.getElementById('AOR').getContext("2d")
  }

  const mintApeOnRide = () => {
    if (generated){
      letsMint(walletA,walletA);
    }
  }
   
  /*
  const downloadApeOnRide = (canvasID) => {
      var canvas = document.getElementById(canvasID);
      let image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      var link = document.createElement('a');
      link.download = apeData.name+" on a "+rideData.ride+".png";
      link.href = image;
      console.log("downloading IMAGE")
      link.click();
  }*/










//FETCH NFTS
    
  const getRideMetadata = async (rideIDArray) => {
      let tempridearray = []
      for (const rideId of rideIDArray) {
          console.log(rideId)
          console.log(rideIDArray[rideId])
          let rideToken = parseInt(rideId.id.tokenId)
          console.log(rideToken)
          let data = await getRideMeta(rideToken)
          console.log(data)
          
          let rideType = await getMetadataTrait(data, "ride type",false)
          
          let testdata = {contract: '0x1748b24faf9780b74e5a9f3feb41553e712e94aa', tokenId: rideToken, name: `AR #${rideToken} (${rideType})`, url: `https://storage.googleapis.com/aperidesnfts/thumbnails/${rideToken}.png`, ride: rideType.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')}
          tempridearray.push(testdata)
          console.log("pushed to ride array")
          
      }
      setNftsR(tempridearray)
      console.log(tempridearray)
  }
    
  const getUsersRides = async (_owner) => {
      let response = await alchemyWeb3.alchemy.getNfts({owner: _owner, contractAddresses: ["0x1748b24faf9780b74e5a9f3feb41553e712e94aa"], withMetadata: false})
      console.log(response)
      await getRideMetadata(response.ownedNfts);
      
  }

  //CHANGE COLLECTION ON USER SELECTION, PASSED TO APE CONTAINER
  const changeCollection = async (collection) => {
    setOsLink([])
    setNfts([])
    let currentNfts = await allNfts
    let data = currentNfts[collection]
    if (data.length === 0) {
      setOsLink(["https://opensea.io/collection/"+openseaLinks[collection],("https://looksrare.org/collections/"+contractAddresses[collection])])
    }
    setState("apes")
    setNfts(data)
    return
  }

  const pfpConcNames = ['trac','bayc','mayc','xbayc','xmayc','gacc','daw','sac','layc','payc', 'jbas','arc','btfd','btyc','lion','aas']
  const fetchAllApes2 = async (address,web3) => {
    let array = [[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]

      const data = await getNftsFromAlchemy(address);
      let testdata = {}
      data.forEach(async (item)=> {
        console.log(item)
        try {
            let nftImage = `https://storage.googleapis.com/nftobjects/${pfpConcNames[contractAddresses.indexOf(item.contract.address.toLowerCase())]}/images/${parseInt(item.id.tokenId)}.png`
            let contractAddy = item.contract.address.toLowerCase()
            let tokenName = contractNames[contractAddresses.indexOf(item.contract.address.toLowerCase())] + " #" + parseInt(item.id.tokenId)
            if ([
                '0x2120d19431e0dd49411e5412629f8e41a72cfabd',
                '0xe83dd605b70b47c8af86580bdd4fcb987ff36e60',
                '0x87212aa99f65611f6d67e0fbad76d06478753704',
                '0xbc4e8115e17d96cfd8e3ecef2b0d6e19d00f70b2',
                '0x8943c7bac1914c9a7aba750bf2b6b09fd21037e0',
                '0xfa969c60a78195c631787d4585ba15a07578c979'].includes(item.contract.address.toLowerCase())) {
                  nftImage = item.media[0].gateway
            } else if (item.contract.address.toLowerCase()==='0xe9626ef8dc3a8bb6bf9e18b44d35266a7ba3d11d') {
              nftImage = `https://storage.googleapis.com/aperidersclub/revealed/images/${parseInt(item.id.tokenId)}.png`
              item.metadata = await getARCMeta(parseInt(item.id.tokenId))
            }
             testdata = {
                contract: contractAddy,
                tokenId: parseInt(item.id.tokenId),
                name: tokenName, url: nftImage,
                background: getBackground(item.metadata, contractAddy),
                fur: getFur(item.metadata, contractAddy),
                clothing: getClothing(item.metadata),
                necklace: getNecklace(item.metadata),
                handbag: getHandbag(item.metadata),
                hair: getHair(item.metadata),
                neck: getNeck(item.metadata),
                back: getBack(item.metadata),
                mouth: getMouth(item.metadata),
                accessory: getMetadataTrait(item.metadata,'accessories'),
                face: item.contract.address.toLowerCase()==="0xfa969c60a78195c631787d4585ba15a07578c979" ? getMetadataTrait(item.metadata,'face') : null,
                data: item.metadata
              }
            
            
        } catch{ testdata ={}}

            let index = contractAddresses.indexOf(item.contract.address.toLowerCase())
            if (index !== -1) {
              array[index].push(testdata)
            } 
      })
      console.log(array);
      return array;
  }


// USER FLOW FUNCTIONS

  //collection: INT
  const collectionClick = (number) => {
   chooseCollection(number)
   changeCollection(number)

  }
    
    const setCollectionBorders = (thisArray) => {
        
        console.log("setting bordeRS")
        let tempArray = collectionBorders
        for (let i = 0; i < 17; i++) {
            console.log(thisArray[i])
            console.log(thisArray[i].length)
            if (thisArray[i].length>0){
                tempArray[i] = "solid 3px #5DA271"
                
            } else {
                tempArray[i] = "solid 3px #D68C45"
            }
        }
        console.log(tempArray)
        setCollectionBordersState(tempArray)
    }

  const restartState = () => {
    setGenerated(false)
    setApeData([])
    chooseCollection(null)
    setRideData([])
    setMintInProgress(false)
    try {
        let ctx = getCanvas()
        ctx.clearRect(0, 0, 3000, 3000);
        document.getElementById("canvasMain").style.display = "none";
    } catch {
        console.log('');
    }
    setState("collection")
  }

  const backState = () => {
    switch (state) {
      case "collection":
        break
      case "apes":
        chooseCollection(null)
        setState("collection")
        break
      case "rides":
        setState("apes")
        break
      case "generate":
        setState("rides")
        setGenerated(false)
        document.getElementById("canvasMain").style.display = "none";
        break
      case "generated":
        setState("rides")
        setGenerated(false)
        setMintInProgress(false)
        document.getElementById("canvasMain").style.display = "none";
        break
      default:
        break
    }

  }










// ANIMATION CONTROLLERS

  const expandAnimation = async () => {
      document.getElementById("expanded").className="disappear"
      await new Promise(r => setTimeout(r, 500));
      setExpanded(false)
  }

  const closePopUp = () => {
    if (popUp && popUpData.type!=="c-loader"){
      setPopUp(false)
    } 
  }
    
    const closeExpanded = () => {
        if (expanded) {
            setExpanded(false)
        }
    }









//  WEB3 TRANSACTIONS


  const letsMint = async (_address,_mintAddress) => {
      
    setMintInProgress(true)

    var web3 = userWeb3

    const photobookABI = require("./abis/photobookABI.json")

    const photobookContract = new web3.eth.Contract(photobookABI, "0x380F0656A2cc032583bd26D4FFE29A27B6079050");
      
      let apeCaddress = apeData.contract;

      let APEHHOLDINGADDRESS;

      if (safeMint && receivingAddress.length === 42) {
        APEHHOLDINGADDRESS = receivingAddress
      } else {
        APEHHOLDINGADDRESS = _mintAddress
      }
      
      console.log(apeData.contract)
      console.log(apeData.tokenId)
      console.log(rideData.contract)
      console.log(rideData.tokenId)
      console.log(APEHHOLDINGADDRESS)

    await photobookContract.methods.mint(apeCaddress,apeData.tokenId,rideData.contract,rideData.tokenId,APEHHOLDINGADDRESS).send({from: _address})
    .on('sending', (payload) => {
      let data = popUpData;
      data.display = true;
      data.title = "Sending Mint Transaction"
      setPopUpData(data)
      setPopUp(true)
    })
    .on('sent', (payload) => {
      setPopUp(false)
      let data = popUpData;
      data.title = "Waiting for you to send the Transaction"
      data.message = "Please accept the transaction in your chosen wallet"
      data.type = "c-loader"
      setPopUpData(data)
      setPopUp(true)
    })
    .on('transactionHash', (hash) => {
      setPopUp(false)
      let data = popUpData;
      data.title = "Transaction Sent"
      data.message = "Waiting for the blockchain to confirm your transaction"
      data.type = "c-loader"
      console.log(hash)
      setPopUpData(data)
      setPopUp(true)
    })
    .on('confirmation', (confirmationNumber, receipt) => {
      //SENDS CONFIRMATION
    })
    .on('receipt', (receipt) => {
      setPopUp(false)
      let data = popUpData
      data.title = "Mint Succesfull"
      data.message = `You have Minted AOR #${receipt.events.Transfer.returnValues.tokenId}`
      data.type = "c-success"
      setPopUpData(data)
      setPopUp(true)
    })
    .on('error', (error, receipt) => {
      setPopUp(false)
      let data = popUpData
      data.title = "Mint Error"
      data.message = error.message
      data.type = "c-error"
      setPopUpData(data)
      setPopUp(true)
      setMintInProgress(false)
    });


  }


//UTILS












//EFFECTS!

  //New ape selected for ride Effect
  useEffect(() => {
    if (expanded) {
      expandAnimation()
    }
    if (state==="apes"){
    setState("rides")
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apeData])

  // New ride selected
  useEffect(() => {
    if (state==="rides"){
      setState("generate")
      setMintInProgress(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rideData])
    


//safemmintcontroler

  const handleChange = async () => {
        if (!safeMint) {
          setState("safemint")
        } else {
          //IMPLEMENT A REACT STATE SWITCH TO AVOID ETRA DATA FETCHHES
            if (isApproved) {
              fetchAllApes2(walletA).then((x)=>{
                setAllNfts(x)
                setCollectionBorders(x)
                  setIsApproved(false)
                setState("collection")
              })
            } else {
                setState("collection")
            }
        }
        setSafeMint(!safeMint)
  };


  const handleChangeReceiving = async (event) => {
        setReceivingAddress(event.target.value)
  }

    const handleSafeMintClick = async () => {
        if (receivingAddress.length===42) {
            let isapproved = await isRideWalletApprovedForApeWallet(receivingAddress, walletA);
            setIsApproved(isapproved)
            if (isapproved) {
                setISMIP(true)
                fetchAllApes2(receivingAddress).then((x)=>{
                    setAllNfts(x)
                    setCollectionBorders(x)
                    setIsApproved(false)
                    setState("collection")
                })
            } else {
                setPopUp(false)
                let data = popUpData
                data.title = "Ape Wallet Not Approved"
                data.message = "Make sure you have successfully sent 0 ETH from your ape-holding wallet to the wallet you are connected with to verify your ownership. This verification lasts 1 hour"
                data.type = "c-error"
                setPopUpData(data)
                setPopUp(true)
            }
        }
    }
    
    const unixTimestamp = (_date) => {
        var date = new Date(_date);
        return Math.floor(date/1000);
    }
    
    const isRideWalletApprovedForApeWallet = async (apeWallet, rideWallet) => {
        console.log(apeWallet)
        console.log(rideWallet)
        const url = 'https://eth-mainnet.g.alchemy.com/v2/FeBF4O8PdhwNkQFNYAnjpWkjBuq74TMQ';
        const options = {
          method: 'POST',
          headers: {accept: 'application/json', 'content-type': 'application/json'},
          body: JSON.stringify({
            id: 1,
            jsonrpc: '2.0',
            method: 'alchemy_getAssetTransfers',
            params: [
              {
                fromBlock: '0x0',
                toBlock: 'latest',
                category: ['erc20','erc721','external','erc1155'],
                withMetadata: true,
                excludeZeroValue: false,
                maxCount: '0x3e8',
                order: 'desc',
                fromAddress: apeWallet,
                toAddress: rideWallet
              }
            ]
          })
        };
        let transfers = await fetch(url, options).then(res => res.json())
        let isapproved = false;
        if (transfers.result.transfers.length > 0) {
            transfers.result.transfers.forEach((transaction) => {
                if ((unixTimestamp(Date.now())-unixTimestamp(transfers.result.transfers[0].metadata.blockTimestamp))<3600){
                    
                    isapproved = true;
                    
                }
                
            })
        }
        return isapproved
    }
    
    const useConnectedWallet = () => {
        setPopUp(false)
        let data = popUpData;
        data.title = "Fetching your connected wallet NFTs"
        data.message = "This may take a second if you own a lot of NFTs"
        data.type = "c-loader"
        setPopUpData(data)
        setPopUp(true)
        fetchAllApes2(walletA).then((x)=>{
            setAllNfts(x)
            setCollectionBorders(x)
            setIsApproved(false)
            setSafeMint(false)
            setReceivingAddress("")
            setISMIP(false)
            setState("collection")
            setPopUp(false)
        })
    }

     const goToBanner = () => {
    backButton()
    }









//RETURN HTML STARTS HERE

  return (

<div className="flexc">

  { popUp ?
    <div id="PopUp" key={popUp} onClick={closePopUp}>
      <p className="sm-txt sdark-txt">{popUpData.title}</p>

      <div className={popUpData.type}></div>

      <p className="xs-txt sdark-txt">{popUpData.message}</p>
    </div>
    :
    <>
    </>
  }
  

  
  
  { expanded ?
    <div id="expanded" className="expanded">
    <div className="flex expandHeader">
      <p className="headerItem"></p>
      <p className="md-txt sdark-txt headerItem flexCenter">Choose an PFP</p>
      <p className="md-txt sdark-txt headerItem alignEnd exitButton" onClick={closeExpanded}>Exit</p>
    </div>
    <div className="flex fwrap">
    {nfts.map((nft, index) => {
        return <NFTCard apeCallback={setApeData} nft={nft} key={index} />
      })}
    </div>
    </div>
    :
    <>
    </>
  }

  { (applicationError!=="") ?
    <div className="appErrorWrapper">
      <div className="appErrorPopUp">
        <p className="sm-txt white-txt">{applicationError}</p>
      </div>
    </div>
    :
    <>
    </>
  }





  <div id="walletPopUp" className="walletPopUpNone">
    <p className="sm-txt sdark-txt">{ fetched ? "Choose your preferred wallet" : "Loading" }</p>
    { chainError ? <p className="xs-txt red-txt">Wrong Network - Please change to Ethereum</p> : <p className="none"></p>}
    { fetched ?
    <div className="flex walletFlex">
      <div onClick={connectMM} id="metamask" className="flexc wallet">
        <img src="metamask.png" alt="metamask" className="walletIcon"></img>
        <p className="xs-txt sdark-txt mgt">Metamask</p>
      </div>
       <div onClick={connectWC} className="flexc wallet">
         <img src="walletconnect.png" alt="walletconnect" className="walletIcon"></img>
        <p className="xs-txt sdark-txt mgt">Wallet Connect</p>
      </div>
        <div onClick={connectCBW} className="flex-center flex-col wallet">
            <img src="coinbasewallet.svg" alt="coinbasewallet" className="walletIcon"></img>
            <p className="xs-txt sdark-txt">Coinbase Wallet</p>
        </div>
    </div>
    :
    <>
    <div className="c-loader"></div>
    <p className="sdark-txt xs-txt mgt">This may take a second if you have a lot of NFTs</p>
    </>
  }

  </div>

          <div id="header" className="header">
              <div className="headerItem flexStart">
                <p className="xs-txt white-txt mgl" onClick={goToBanner}>Back</p>
              </div>
                    
              <div className="flex headerItem" id="aperidesTextLogo">
                <img src="bannertxt.png" alt="Ape Rides logo" height="35px" className="op9"></img>
              </div>
                
              <div className="headerItem flexEnd">
                  { walletA ?
                  <button onClick={popUpWallet}>{'0x'+walletA.slice(2,7).toUpperCase()+'..'+walletA.slice(35).toUpperCase()}</button>
                  :
                  <button onClick={popUpWallet}>CONNECT WALLET</button>
                  }
              </div>

          </div>



  <div id="lockup" className="lockup">

    




  
    <>
    <p className="sm-txt white-txt">
        Welcome to the Ape Rides Garage
    </p>
    { !walletA ?
    <p className="sm-txt white-txt mgb50">
      Connect a wallet to get started
    </p>
        :
    <></>
    }
    { walletA ?
        <>
        {!isSafeMintInProgress ?
        <>
        </>
        :
            <div className="flexc">
                <p className="sm-txt white-txt">Viewing PFPs from {receivingAddress.slice(0,7).toUpperCase()+'..'+receivingAddress.slice(35).toUpperCase()} and Rides from {walletA.slice(0,7).toUpperCase()+'..'+walletA.slice(35).toUpperCase()}</p>
            {(state==="collection") ? <button className="mgt mgb" onClick={useConnectedWallet}>USE CONNECTED WALLET</button> : <></>}
            </div>
        }

    { (state==="safemint") ?

    <>
        {(!isApproved && (state==="collection" || state==="safemint")) ?
        <div className="flex mgb">
          <p className="xs-txt white-txt">Choose a PFP from another wallet you own?</p>
          <input checked={safeMint} onChange={handleChange} type="checkbox" className="safemintbox"></input>
        </div>
        :
        <></>}
        <p className="sm-txt white-txt">1. Enter the wallet address that holds your pfp(s)</p>
        <input id="receiveAddress" className="address mgt mgb" type="text" placeholder="Paste pfp wallet address here" value={receivingAddress} onChange={handleChangeReceiving}></input>
        <p className="sm-txt white-txt">2. Verify your ownership of that wallet: Send 0 (zero) ETH from the above wallet to the connected wallet</p>
        <p className="sm-txt white-txt">3. Has the transaction successfully completed? Then fetch your pfps!</p>

     {isApproved ? <div className="flexc"><p className="sm-txt white-txt mgb">Wallet approved, loading pfps</p><div className="c-loader mgt"></div></div> : <button className="mgt" onClick={handleSafeMintClick}>Fetch pfps</button>}

    </>

    : 
    <></>}

    { (state==="collection") ?
    <div className="flexc apes">
    <p className="md-txt white-txt mgbz mgt50">CHOOSE A COLLECTION</p>
        {(!isApproved && (state==="collection" || state==="safemint")) ?
        <div className="flex mgb">
          <p className="xs-txt white-txt">Choose an pfp from another wallet you own?</p>
          <input checked={safeMint} onChange={handleChange} type="checkbox" className="safemintbox"></input>
        </div>
        :
        <></>}
    <div className="flex">
        <div id="c1" onClick={() => collectionClick(1)} className="collectionLogo bayc" style={{border: collectionBorders[1]}}></div>
        <div id="c2" onClick={() => collectionClick(2)} className="collectionLogo mayc" style={{border: collectionBorders[2]}}></div>
        <div id="c3" onClick={() => collectionClick(3)} className="collectionLogo xbayc" style={{border: collectionBorders[3]}}></div>
          <div id="c4" onClick={() => collectionClick(4)} className="collectionLogo xmayc" style={{border: collectionBorders[4]}}></div>
          <div id="c5" onClick={() => collectionClick(5)} className="collectionLogo gacc" style={{border: collectionBorders[5]}}></div>
          <div id="c6" onClick={() => collectionClick(6)} className="collectionLogo daw" style={{border: collectionBorders[6]}}></div>
          <div id="c0" onClick={() => collectionClick(0)} className="collectionLogo trac" style={{border: collectionBorders[0]}}></div>
          <div id="c7" onClick={() => collectionClick(7)} className="collectionLogo sac" style={{border: collectionBorders[7]}}></div>
          <div id="c8" onClick={() => collectionClick(8)} className="collectionLogo layc" style={{border: collectionBorders[8]}}></div>
          <div id="c9" onClick={() => collectionClick(9)} className="collectionLogo payc" style={{border: collectionBorders[9]}}></div>
          <div id="c10" onClick={() => collectionClick(10)} className="collectionLogo jbas" style={{border: collectionBorders[10]}}></div>
          <div id="c11" onClick={() => collectionClick(11)} className="collectionLogo arc" style={{border: collectionBorders[11]}}></div>
          <div id="c12" onClick={() => collectionClick(12)} className="collectionLogo btfd" style={{border: collectionBorders[12]}}></div>
          <div id="c13" onClick={() => collectionClick(13)} className="collectionLogo baddies" style={{border: collectionBorders[13]}}></div>
          <div id="c14" onClick={() => collectionClick(14)} className="collectionLogo btyc" style={{border: collectionBorders[14]}}></div>
          <div id="c15" onClick={() => collectionClick(15)} className="collectionLogo lazylions" style={{border: collectionBorders[15]}}></div>
          { (Date.now()>1677787500000) ? <div id="c16" onClick={() => collectionClick(16)} className="collectionLogo aas" style={{border: collectionBorders[16]}}></div> : <></> }
    </div>
    </div>
    :
    <></>}

    { (state === "apes") ?
      <div>
        <ApeContainer dataCallback={dataCallback} nfts={nfts} walletA={walletA} changeCollection={changeCollection} collection={collection} osLink={osLink} setExpanded={setExpanded} apeData={apeData} setApeData={setApeData}/>
      </div>
      :
      <>
      </>
    }

    { (state === "rides") ?
    <div className="flexc rides">
    <p className="md-txt white-txt">Choose a Ride</p>
      <div className="flex fwrap scroll">
        {nftR.map((nft, index) => {
          return <NFTCard apeCallback={setRideData} nft={nft} key={index} />
        })}
      </div>
      </div>
      :
      <>
      </>
      
    }
    
    { !(state==="collection" || state==="safemint" || state==="generate" ) ?
        
        <div className="flex mgt50">
        { (state==="generate" || state==="generated")
        ?
          <div className="flex restartBox mgr" onClick={restartState}>
            <div className="restart"></div>
            <p className="sm-txt white-txt mgl">restart</p>
          </div>
        :
        <></>
        }
          <div className="flex restartBox mgl" onClick={backState}>
            <div className="back"></div>
            <p className="sm-txt white-txt mgl">back</p>
          </div>
        </div>
    :
        <></>
    }

    { (state === "generate") ?
      <div className="flexc generate">

        <p className="md-txt white-txt">Your selection</p>

        <div className="flex gg20">

          <div className="flexc">
            <div id="chosenApe">
              <img src={apeData.url} alt={apeData.name} height="100%" className="roundedCorner"></img>
            </div>
            <p className="sm-txt white-txt">{apeData.name}</p>
          </div>

          <p className="lg-txt white-txt">+</p>

          <div className="flexc">
            <div id="chosenRide">
              <img src={rideData.url} alt={rideData.name} height="100%" className="roundedCorner"></img>
            </div>
            <p className="sm-txt white-txt">{rideData.name}</p>
          </div>

        </div>

        
        {generated
            ?
            <></>
            :
            <>
            <button onClick={dataCallback} className="mgb">Let's Ride</button>
            <div className="flex">
            <div className="flex restartBox mgr" onClick={restartState}>
              <div className="restart"></div>
              <p className="sm-txt white-txt mgl">restart</p>
            </div>
            <div className="flex restartBox mgl" onClick={backState}>
              <div className="back"></div>
              <p className="sm-txt white-txt mgl">back</p>
            </div>
            </div>
          </>
        }
        
        <div id="canvasMain" className="canvasMain">
        <div id="canvasHider" className="canvasHider"><p className="md-txt sdark-txt mgb">Your Rider is being generated</p><div className="c-loader mgt"></div></div>
            <canvas id="AOR" width="3000" height="3000" className="mgt">
                Your browser does not support HTML5 canvas
            </canvas>
            { generated
                ?
                <>
                {!mintInProgress ? <button className="mgt" onClick={()=>{mintApeOnRide()}}>Mint ApeRider</button> : <></> }
                <div className="flex mgt">
                    <div className="flex restartBox mgr" onClick={restartState}>
                      <div className="restart"></div>
                      <p className="sm-txt white-txt mgl">restart</p>
                    </div>
                    <div className="flex restartBox mgl" onClick={backState}>
                      <div className="back"></div>
                      <p className="sm-txt white-txt mgl">back</p>
                    </div>
                </div>
                </>
                :
                <></>
            }
        </div>

      </div>
      :
      <>
      </>
    }

  
  </>
  :
  <>
  </>
  }
    </>
  </div>


</div>
  );
}

class Garage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {window: props.window, setWindow: props.setWindow};
    this.backButton = this.backButton.bind(this);
  }



  backButton() {
    this.state.setWindow("Explore")
  }

  render() {
    if (this.state.window==="Garage") {
    return(
      <>
      <GarageApp backButton={this.backButton}/>
      </>
      );
    } else {
      return (
        <>
        </>
      );
    }
  }


}

export default Garage
